import { Button, message, Modal, notification, Spin } from "antd";
import React, { useEffect, useState } from "react";


import OTPInput, { ResendOTP } from "otp-input-react";
import { authStep1 } from "../features/auth/authService";
import { apihitCall, apiHitWithTempToken } from "../service/authService";

import { getErrorMessage } from "../utils/functions";
import { useDispatch } from "react-redux";
import { setUserData } from "../features/auth/authSlice";


export default function VerifyOtp({
  email,
  verifyOtpModalOpen,
  verifyOtpModalClose,
  loginData,
  onSuccess

}) {
  //   const { auth,token } = useSelector(({user}) => ({...user}));

  

  const [loader, setLoader] = useState(false);
  // error 
   const [maskedEmail] = useState(maskEmail(email??""));
  // const [maskedPhone] = useState(
  //   maskPhonephone(loginData?.phone)
  // );
 
 // const [maskedEmail] = useState(email);
  const [maskedPhone] = useState(
   ""
  );
  const [OTP, setOTP] = useState("");
  console.log("dmn1")
  console.log(loginData?.otp)
 

  useEffect(() => {
    if (
      loginData?.otp &&
      String(loginData.otp).length > 3 &&
      loginData.otp !== 0
    ) {
      setOTP(loginData.otp);
    }
  }, [loginData?.otp]);

// const dispatch=useDispatch()


const dispatch = useDispatch();

  const finalOtpVerification = async () => {
    try {
      const formData = new FormData();
      formData.append("action", "verify_login_otp");
      formData.append("otp", OTP);
     // formData.append("userId", loginData?.id);
          
      formData.append("sessionid", loginData?.session);
      
      const res = await apiHitWithTempToken(formData);
      
      if (res?.data?.status === 200) {
        dispatch(setUserData(res?.data?.result[0]));
        onSuccess(res?.data?.result?.[0].id)
      //  onSuccess(loginData?.id)
             
        setLoader(false);
        
      } else {
        setLoader(false);

        notification.error({ message: res?.data?.message });
      }
    } catch (error) {
      setLoader(false);
      const m = getErrorMessage(error || "Incorrect Otp");
      message.error( m);
    } finally {
      setLoader(false);
    }
  };
  const otpVarify = async () => {
    if (!OTP || OTP.toString().length < 6) {
      
      message.destroy()
      message.error("Please fill otp")

      // Notify("error", "Please fill otp");
      return;
    }

    setLoader(true);
    finalOtpVerification();

  };

  const resendOtp = async () => {
    setLoader(true);
    //const resdata = await authStep1();
    const formData = new FormData();
    formData.append("action", "resend_login_passcode");
    formData.append("sessionid", loginData?.session);
    //formData.append("userId", loginData?.id);
   // formData.append("auterizetokenid", loginData?.id);
   // let res = await apihitCall(formData, resdata?.data?.access_token);
    let res = await apiHitWithTempToken(formData);
    if (res?.data?.status === 400) {
      setLoader(false);

    } else {
      setLoader(false);
      message.error( res?.data?.message);

    }
  };

  function maskEmail(email) {
    const [user, domain] = email.split("@");
    const maskedUser =
      user[0] + "*".repeat(user.length - 2) + user[user.length - 1];
    return maskedUser + "@" + domain;
  }

  function maskPhonephone(phone) {
    // Replace all digits except the last four with asterisks
    const maskedPhone = phone.replace(/\d(?=\d{4})/g, "*");
    // Return the masked phone
    return maskedPhone;
  }

  return (
    <Modal
      centered
      title="Verify Otp"
      footer={null}
      open={verifyOtpModalOpen}
      onCancel={verifyOtpModalClose}
    >
      <div className=" p-2">
        <p>
          {" "}
          You are almost there! We have sent an OTP to your email address{" "}
          {maskedEmail} and your phone number {maskedPhone}.{" "}
        </p>
        <div className="p-4">
          <OTPInput
            value={OTP}
            onChange={setOTP}
            autoFocus
            OTPLength={6}
            otpType="number"
            disabled={false}
          />
        </div>
        <div>
          <ResendOTP
            className="resend-otp"
            onResendClick={(otp) => resendOtp(otp)}
          />
        </div>
        {loader ? (
          <div
            className="d-flex align-items-center justify-content-center w-100"
            style={{ height: 70 }}
          >
            <Spin className="mx-auto" />
          </div>
        ) : (
          <>
            <Button type="primary" className=" mt-4" block onClick={otpVarify}>
              Continue
            </Button>
          </>
        )}
      </div>
    </Modal>
  );
}
