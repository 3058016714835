
import axiosHIT from 'axios'
import { getClientSecret, getClient_id } from '../../utils/functions';
// A mock function to mimic making an async request for data
export function login(amount = 1) {
  return new Promise((resolve) =>
    setTimeout(() => resolve({ data: amount }), 500)
  );
}




export const authStep1=(code)=>{
  const formdata = new FormData();
  formdata.append("client_id", getClient_id());
  formdata.append("client_secret", getClientSecret());
  formdata.append("grant_type",code?'authorization_code': "client_credentials");
  if(code){
    formdata.append("code",code);
  }
  
  return axiosHIT({
    url: `${process.env.REACT_APP_API_SCHEME}://${process.env.REACT_APP_API_HOST}/Token.php`,
    method: "post",
    data: formdata,
    headers: {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
      
    },
  });
}
export const authStep2=(data)=>{
  const {email,password,token,fcmToken}=data
  const formdata = new FormData();
  formdata.append("email", email);
  formdata.append("password", password);
  formdata.append("action", "loginNew");
  formdata.append('country', 'US');
  formdata.append('deviceType', '1');
  formdata.append('deviceId', '');
  formdata.append('webfcm', fcmToken);
  return axiosHIT({
    url: `${process.env.REACT_APP_API_SCHEME}://${process.env.REACT_APP_API_HOST}/${process.env.REACT_APP_API_VERSION}/main.php`,
    method: "post",
    data: formdata,
    headers: {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
      Authorization:`Bearer ${token}`
    },
  });
}
export const authStep3=(userid)=>{
  const formdata = new FormData();
  formdata.append("userid", userid);
  return axiosHIT({
    url: `${process.env.REACT_APP_API_SCHEME}://${process.env.REACT_APP_API_HOST}/TokenUser.php?response_type=code&client_id=${getClient_id()}&state=xyz`,
    method: "post",
    data: formdata,
    headers: {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
    },
  });
}
export const generateSecureToken=(authCode)=>{
  const formdata = new FormData();
  formdata.append("client_id", getClient_id());
  formdata.append("client_secret", getClientSecret());
  formdata.append("grant_type", "client_credentials");
  formdata.append('code', authCode);
  return axiosHIT({
    url: `${process.env.REACT_APP_API_SCHEME}://${process.env.REACT_APP_API_HOST}/Token.php`,
    method: "post",
    data: formdata,
    headers: {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
    },
  });
}




