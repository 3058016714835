import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useNavigate } from "react-router-dom";
import {
  Popover,
  Divider,
  Drawer,
  Dropdown,
  Menu,
  message,
  Tooltip,
} from "antd";
import { MdKeyboardArrowDown, MdKeyboardArrowRight } from "react-icons/md";
import { HiOutlineShoppingBag } from "react-icons/hi";
import { BiWalletAlt } from "react-icons/bi";
import { IoMdNotificationsOutline } from "react-icons/io";
import "../css/Popover.css";
import { AiOutlineGift } from "react-icons/ai";
import { FiUsers, FiLogOut } from "react-icons/fi";
import { GrNotes } from "react-icons/gr";
import { GoLocation } from "react-icons/go";
import "./style/style.css";
import { logout, logoutapi } from "../features/auth/authSlice";
import ShowForType from "../Hooks/ShowForType";
import { userTypes } from "../utils/constants";
import HeaderChatList from "./common/dropdowns/HeaderChatList";
import { FaChartLine, FaIdBadge, FaListAlt, FaTasks } from "react-icons/fa";
import { isDoctor } from "../utils/functions";
import { IoIosArrowForward } from "react-icons/io";
import { ReactSession } from "react-client-session";
import HeaderMenu from "../assets/images/headermenu.png";
import TurnOff from "../assets/images/turn-off.png";
import { apihitCall } from "../service/authService";

const Content = () => {
  const navigation = useNavigate();
  const { user } = useSelector(({ user }) => user);
  const dispatch = useDispatch();
  const logoutAction = () => {
    dispatch(logout());
    navigation("/");
  };
  return (
    <div>
      <div className="header-item">
        <div className="img-item">
          {/*<FaUserTie style={{ zoom: 2.5 }} />*/}
          <img
            src="assets/images/profile-img.png"
            alt=""
            style={{
              height: "40%",
            }}
          />
        </div>
        <div className="username-container">
          <div className="name-text-container">
            <span style={{ width: "60%" }}>{user?.username}</span>
            <MdKeyboardArrowRight />
          </div>
          <div className="link-view-container">
            <span className="link-view">View and edit profile</span>
            <span className="link-view-per">15% completed</span>
          </div>
        </div>
      </div>
      <Divider />
      {/* item */}
      <div
        onClick={() => navigation("/order")}
        className="item-container"
        style={{ flexDirection: "inherit", width: "100%" }}
      >
        <div className="item-section-1">
          <HiOutlineShoppingBag style={{ zoom: 1.3, color: "#9FA6AD" }} />
          <span className="link-item">My order</span>
        </div>
        <MdKeyboardArrowRight />
      </div>
      {/* item */}
      <div
        onClick={() => navigation("/wallet")}
        className="item-container"
        style={{ flexDirection: "inherit", width: "100%" }}
      >
        <div className="item-section-1">
          <BiWalletAlt style={{ zoom: 1.3, color: "#9FA6AD" }} />
          <span className="link-item">Wallet</span>
        </div>
        <MdKeyboardArrowRight />
      </div>
      {/* item */}
      <div
        onClick={() => navigation("/notification")}
        className="item-container"
        style={{ flexDirection: "inherit", width: "100%" }}
      >
        <div className="item-section-1">
          <IoMdNotificationsOutline style={{ zoom: 1.3, color: "#9FA6AD" }} />
          <span className="link-item">Notificationsss</span>
        </div>
        <MdKeyboardArrowRight />
      </div>
      {/* item */}
      <div
        onClick={() => navigation("/wallet")}
        className="item-container"
        style={{ flexDirection: "inherit", width: "100%" }}
      >
        <div className="item-section-1">
          <AiOutlineGift style={{ zoom: 1.3, color: "#9FA6AD" }} />
          <span className="link-item">Refer & earn</span>
        </div>
        <MdKeyboardArrowRight />
      </div>
      {/* item /family-profile */}
      <div
        onClick={() => navigation("/family-profile")}
        className="item-container"
        style={{ flexDirection: "inherit", width: "100%" }}
      >
        <div className="item-section-1">
          <FiUsers style={{ zoom: 1.3, color: "#9FA6AD" }} />
          <span className="link-item">Family members</span>
        </div>
        <MdKeyboardArrowRight />
      </div>
      {/* item */}
      <div
        className="item-container"
        style={{ flexDirection: "inherit", width: "100%" }}
      >
        <div className="item-section-1">
          <GoLocation style={{ zoom: 1.3, color: "#9FA6AD" }} />
          <span className="link-item">Manage address</span>
        </div>
        <MdKeyboardArrowRight />
      </div>
      <Divider />
      {/* item */}
      <div
        className="item-container"
        style={{ flexDirection: "inherit", width: "100%" }}
      >
        <div onClick={logoutAction} className="item-section-1">
          <FiLogOut style={{ zoom: 1.3, color: "#9FA6AD" }} />
          <span className="link-item">Logout</span>
        </div>
        <MdKeyboardArrowRight />
      </div>
    </div>
  );
};

const DropDownMenu = () => {
  const { user } = useSelector(({ user }) => user);
  return (
    <Popover
      overlayClassName="user-container"
      placement="bottomRight"
      content={<Content />}
      trigger="click"
    >
      <div className="dropdown-container">
        <p className="link-user">{user?.username}</p>
        <MdKeyboardArrowDown style={{ zoom: 2, color: "#1E90FF" }} />
      </div>
    </Popover>
  );
};

const HeaderComponent = (props) => {
  const { onLoginClick, onSignupClick } = props;
  const { isAuth, user, service } = useSelector(({ user }) => user);
  const navigation = useNavigate();
  const query = new URLSearchParams(window.location.search);
  return (
    <>
      {query.get("t") !== "m" ? (
        <header className="main-header">
          <div className="container">
            <div className="main-header-inner flex align-items-center">
              <div onClick={() => navigation("/home")} className="header-logo">
                {/* <img src="assets/images/2424.png" alt="" /> */}
                <img src="assets/images/logo-black.svg" alt="" />
              </div>

              <div className="header-menu-right flex align-items-center">
                <div className="service-menu hide-for-small-device">
                  <ul className="menu-ul-container">
                    <li>
                      <Dropdown
                        overlay={() => {
                          return (
                            <Menu className="item-container">
                              {(service?.data ?? []).map((i) => (
                                <Menu.Item
                                  onClick={
                                    () =>
                                      (window.location =
                                        window.location.origin +
                                        `/services/${i?.category_id}`)
                                    //navigation(`/services/${i?.category_id}`)
                                  }
                                  className="item-main"
                                >
                                  <div className="d-flex align-items-center">
                                    {i?.category_id === "778" ||
                                    i?.category_id === "801" ? (
                                      <img
                                        src={
                                          i?.iconImage !== ""
                                            ? i?.iconImage
                                            : i?.category_id === "778"
                                            ? "/assets/images/hospice.png"
                                            : "/assets/images/physical-therapy.png"
                                        }
                                        style={{ width: 20, height: 20 }}
                                        alt=""
                                      />
                                    ) : (
                                      <img
                                        src={i?.iconImage}
                                        style={{ width: 20, height: 20 }}
                                        alt=""
                                      />
                                    )}

                                    <span className="detail-text ml-1">
                                      {i?.category_name}
                                    </span>
                                  </div>
                                </Menu.Item>
                              ))}
                            </Menu>
                          );
                        }}
                        placement="bottomLeft"
                        arrow
                      >
                        <span
                          style={{
                            fontWeight: 500,
                            fontSize: 14,
                            textTransform: "uppercase",
                          }}
                        >
                          Services
                        </span>
                      </Dropdown>
                    </li>
                    <ShowForType type={userTypes.CASE_MANAGER}>
                      <li>
                        <Link to={"/case-manager/bookings"}>Case Manager</Link>
                      </li>
                    </ShowForType>

                    <li>
                      <Dropdown
                        overlay={() => {
                          return (
                            <Menu className="item-container">
                              <Menu.Item
                                onClick={() =>
                                  (window.location =
                                    window.location.origin + `/about-us/`)
                                }
                                className="item-main"
                              >
                                <div className="detail-item">
                                  <span className="detail-text">About us</span>
                                </div>
                              </Menu.Item>
                              <Menu.Item
                                onClick={() =>
                                  (window.location =
                                    window.location.origin +
                                    `/service-partner/`)
                                }
                                className="item-main"
                              >
                                <div className="detail-item">
                                  <span className="detail-text">
                                    Service Partners
                                  </span>
                                </div>
                              </Menu.Item>
                            </Menu>
                          );
                        }}
                        placement="bottomLeft"
                        arrow
                      >
                        <span
                          style={{
                            fontWeight: 500,
                            fontSize: 14,
                            textTransform: "uppercase",
                          }}
                        >
                          COMPANY
                        </span>
                      </Dropdown>
                    </li>
                  </ul>
                </div>
                {isAuth ? (
                  <>
                    <div className="header-action">
                      <DropDownMenu />
                    </div>
                  </>
                ) : (
                  <>
                    <div className="header-action auth-login-btns">
                      <button
                        onClick={() => onLoginClick(true)}
                        className="btn btn-outline"
                      >
                        Login
                      </button>
                      <button
                        onClick={() => onSignupClick(true)}
                        className="btn btn-primary"
                      >
                        Sign up
                      </button>
                    </div>
                  </>
                )}
              </div>
            </div>
            {!isAuth && (
              <div className="auth-login-btns small-device-only">
                <button
                  onClick={() => onLoginClick(true)}
                  className="btn btn-outline"
                >
                  Login
                </button>
                <button
                  onClick={() => onSignupClick(true)}
                  className="btn btn-primary"
                >
                  Sign up
                </button>
              </div>
            )}

            <div className="service-menu show-for-small-device">
              <ul className="menu-ul-container">
                <li>
                  <Dropdown
                    overlay={() => {
                      return (
                        <Menu className="item-container">
                          {(service?.data ?? []).map((i) => (
                            <Menu.Item
                              onClick={
                                () =>
                                  (window.location =
                                    window.location.origin +
                                    `/services/${i?.category_id}`)
                                //navigation(`/services/${i?.category_id}`)
                              }
                              className="item-main"
                            >
                              {i?.category_id === "778" ||
                              i?.category_id === "801" ? (
                                <img
                                  src={
                                    i?.iconImage !== ""
                                      ? i?.iconImage
                                      : i?.category_id === "778"
                                      ? "/assets/images/hospice.png"
                                      : "/assets/images/physical-therapy.png"
                                  }
                                  style={{ width: 20, height: 20 }}
                                  alt=""
                                />
                              ) : (
                                <img
                                  src={i?.iconImage}
                                  style={{ width: 20, height: 20 }}
                                  alt=""
                                />
                              )}

                              <div className="detail-item">
                                <span className="detail-text">
                                  {i?.category_name}
                                </span>
                              </div>
                            </Menu.Item>
                          ))}
                        </Menu>
                      );
                    }}
                    placement="bottomLeft"
                    arrow
                  >
                    <span
                      style={{
                        fontWeight: 500,
                        fontSize: 14,
                        textTransform: "uppercase",
                      }}
                    >
                      Services
                    </span>
                  </Dropdown>
                </li>

                {user?.user_type === "3" && (
                  <li>
                    <Link to={"/case-manager/bookings"}>Case Manager</Link>
                  </li>
                )}
                <li>
                  <Dropdown
                    overlay={() => {
                      return (
                        <Menu className="item-container">
                          <Menu.Item
                            onClick={() =>
                              (window.location =
                                window.location.origin + `/about-us/`)
                            }
                            className="item-main"
                          >
                            <div className="detail-item">
                              <span className="detail-text">About us</span>
                            </div>
                          </Menu.Item>
                          <Menu.Item
                            onClick={() =>
                              (window.location =
                                window.location.origin + `/service-partner/`)
                            }
                            className="item-main"
                          >
                            <div className="detail-item">
                              <span className="detail-text">
                                Service Partners
                              </span>
                            </div>
                          </Menu.Item>
                        </Menu>
                      );
                    }}
                    placement="bottomLeft"
                    arrow
                  >
                    <span
                      style={{
                        fontWeight: 500,
                        fontSize: 14,
                        textTransform: "uppercase",
                      }}
                    >
                      COMPANY
                    </span>
                  </Dropdown>
                </li>
              </ul>
            </div>
          </div>
        </header>
      ) : null}
    </>
  );
};

const MobileComponent = () => {
  const navigation = useNavigate();
  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div className="mobile-header">
        {open && <HeaderDrawer open={open} onClose={onClose} />}
        <div onClick={() => navigation("/home")} className="header-logo">
          <img
            style={{ width: 125 }}
            src="assets/images/logo-black.svg"
            alt=""
          />
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          {/* <ShowForType type={userTypes.CASE_MANAGER}>
            <li className="header-item-menu">
              <HeaderChatList />
            </li>
          </ShowForType> */}
          {/* <div style={{ width: "20px" }} /> */}
          <div onClick={showDrawer}>
            <img style={{ width: 24, aspectRatio: "1/1" }} src={HeaderMenu} />
          </div>
        </div>
      </div>
    </>
  );
};

const HeaderDrawer = ({ open, onClose }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector(({ user }) => ({
    ...user,
  }));
  const {  token } = useSelector(({ user }) => user);
  const logutApi = async () => {
    const formData = new FormData();

    formData.append("action", "userLogout");
   
    formData.append("country", "US");
    formData.append("auterizetokenid", user?.id);
    formData.append("id", user?.id);

    let res = await apihitCall(formData, token);

   
  };
  const logoutUser = async () => {
    await  logutApi()
    ReactSession.setStoreType("localStorage");
    ReactSession.set("sessionTokenMedset", "");
    
    dispatch(logout());
    navigate("/home");
    message.success("Logout success");
  };

  return (
    <Drawer
      width={"85%"}
      placement="right"
      closable={false}
      onClose={onClose}
      open={open}
    >
      <>
        <img
          style={{ width: 125, marginLeft: "12px" }}
          src="assets/images/logo-black.svg"
          alt=""
        />

        <Divider />

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <ul style={{ margin: 0, padding: 0, width: "100%" }}>
            <li>
              <NavLink
                to={isDoctor(user) ? "/doctor/appointments" : "/provider"}
                style={{
                  color:
                    window.location.pathname.includes("/provider") ||
                    window.location.pathname.includes("/doctor/appointments")
                      ? "#1890ff"
                      : "#000000",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <span>
                  {window.location.pathname.includes("/provider") ? (
                    <img src="assets/images/personal_injury.svg" alt="" />
                  ) : (
                    <img src="assets/images/personal_injury.svg" alt="" />
                  )}
                </span>
                <span style={{ marginLeft: "10px" }}>
                  {isDoctor(user) ? "Appointments" : "Provider"}
                </span>
              </NavLink>
            </li>
            <Divider />

            <li style={{ marginTop: "10px" }}>
              <NavLink
                to={"/case-manager/patient/list"}
                style={{
                  color: window.location.pathname.includes(
                    "/case-manager/patient/"
                  )
                    ? "#1890ff"
                    : "#000000",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <span>
                  <img src="assets/images/personal_injury.svg" alt="" />
                </span>
                <span style={{ marginLeft: "12px" }}>Patients</span>
              </NavLink>
            </li>
            <Divider />

            <RefferalMenu isMobile={true} />
            <Divider />

            <li style={{ marginTop: "10px" }}>
              <NavLink
                to={"/case-manager/notification"}
                style={{
                  color: window.location.pathname.includes("/notification")
                    ? "#1890ff"
                    : "#000000",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <span>
                  {window.location.pathname.includes(
                    "/case-manager/notification"
                  ) ? (
                    <img src="assets/images/notifications_nav.svg" alt="" />
                  ) : (
                    <img src="assets/images/notifications_nav.svg" alt="" />
                  )}
                </span>
                <span style={{ marginLeft: "12px" }}>Alerts</span>
              </NavLink>
            </li>
            <Divider />

            <li style={{ marginTop: "10px" }}>
              <NavLink
                to={"/case-manager/notes"}
                style={{
                  color: window.location.pathname.includes("/notes")
                    ? "#1890ff"
                    : "#000000",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <span>
                  {window.location.pathname.includes("/case-manager/notes") ? (
                    <GrNotes
                      size={18}
                      color={"red"}
                      style={{ marginLeft: "1px" }}
                    />
                  ) : (
                    <GrNotes
                      size={18}
                      color={"#8A8A8A"}
                      style={{ marginLeft: "1px" }}
                    />
                  )}
                </span>
                <span style={{ marginLeft: "16px" }}>Notes</span>
              </NavLink>
            </li>
            <Divider />

            <li style={{ marginTop: "10px" }}>
              <NavLink
                to={"/case-manager/analytics"}
                style={{
                  color: window.location.pathname.includes("/analytics")
                    ? "#1890ff"
                    : "#000000",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <span>
                  {window.location.pathname.includes(
                    "/case-manager/analytics"
                  ) ? (
                    <FaChartLine
                      size={18}
                      color={"#8A8A8A"}
                      style={{ marginLeft: "1px" }}
                    />
                  ) : (
                    <FaChartLine
                      size={18}
                      color={"#8A8A8A"}
                      style={{ marginLeft: "1px" }}
                    />
                  )}
                </span>
                <span style={{ marginLeft: "16px" }}>Analytics</span>
              </NavLink>
            </li>
            <Divider />

            <li style={{ marginTop: "10px" }}>
              <NavLink
                to={"/case-manager/profile-detail"}
                style={{
                  color: window.location.pathname.includes("/profile-detail")
                    ? "#1890ff"
                    : "#000000",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <span>
                  <img src="assets/images/personal_injury.svg" alt="" />
                </span>
                <span style={{ marginLeft: "16px" }}>Account</span>
              </NavLink>
            </li>
            <Divider />

            <li style={{ marginTop: "10px" }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
                onClick={logoutUser}
              >
                <span>
                  <img
                    src={TurnOff}
                    style={{ width: 24, aspectRatio: "1/1" }}
                    alt=""
                  />
                </span>
                <span style={{ marginLeft: "16px", color: "#DC3545" }}>
                  Logout
                </span>
              </div>
            </li>
            <Divider />
          </ul>
        </div>
      </>
    </Drawer>
  );
};

export const CaseMangerHeaderComponent = (props) => {
  const navigation = useNavigate();
  const { user } = useSelector(({ user }) => ({
    ...user,
  }));

  return (
    <>
      <MobileComponent />
      <header className="main-header">
        <div className="container">
          <div className="main-header-inner">
            <div
              onClick={() => navigation("/home")}
              className="header-logo"
              style={{ position: "absolute", left: 12, top: 21 }}
            >
              <img
                style={{ width: 125 }}
                src="assets/images/logo-black.svg"
                alt=""
              />
            </div>

            <div className="header-menu-center flex align-items-center">
              <div className="manager-menu hide-for-md-device">
                <ul style={{ margin: 0, padding: 0 }}>
                  <li className="header-item-menu">
                    <NavLink
                      to={isDoctor(user) ? "/doctor/appointments" : "/provider"}
                      style={{
                        color:
                          window.location.pathname.includes("/provider") ||
                          window.location.pathname.includes(
                            "/doctor/appointments"
                          )
                            ? "#1890ff"
                            : "#000000",
                      }}
                    >
                      <span>
                        {window.location.pathname.includes("/provider") ? (
                          <img src="assets/images/personal_injury.svg" alt="" />
                        ) : (
                          <img src="assets/images/personal_injury.svg" alt="" />
                        )}
                      </span>
                      {isDoctor(user) ? "Appointments" : "Provider"}
                    </NavLink>
                  </li>

                  <li className="active">
                    <NavLink
                      to={"/case-manager/patient/list"}
                      style={{
                        color: window.location.pathname.includes(
                          "/case-manager/patient/"
                        )
                          ? "#1890ff"
                          : "#000000",
                      }}
                    >
                      <span>
                        <img src="assets/images/personal_injury.svg" alt="" />
                      </span>
                      Patients
                    </NavLink>
                  </li>

                  <RefferalMenu />

                  <li className="header-item-menu">
                    <NavLink
                      to={"/case-manager/notification"}
                      style={{
                        color: window.location.pathname.includes(
                          "/notification"
                        )
                          ? "#1890ff"
                          : "#000000",
                      }}
                    >
                      <span>
                        {window.location.pathname.includes(
                          "/case-manager/notification"
                        ) ? (
                          <img
                            src="assets/images/notifications_nav.svg"
                            alt=""
                          />
                        ) : (
                          <img
                            src="assets/images/notifications_nav.svg"
                            alt=""
                          />
                        )}
                      </span>
                      Alerts
                    </NavLink>
                  </li>

                  <li className="header-item-menu">
                    <NavLink
                      to={"/case-manager/notes"}
                      style={{
                        color: window.location.pathname.includes("/notes")
                          ? "#1890ff"
                          : "#000000",
                      }}
                    >
                      <span>
                        {window.location.pathname.includes(
                          "/case-manager/notes"
                        ) ? (
                          <GrNotes color={"red"} />
                        ) : (
                          <GrNotes />
                        )}
                      </span>
                      Notes
                    </NavLink>
                  </li>

                  <li className="header-item-menu">
                    <NavLink
                      to={"/case-manager/analytics"}
                      style={{
                        color: window.location.pathname.includes("/analytics")
                          ? "#1890ff"
                          : "#000000",
                      }}
                    >
                      <span>
                        {window.location.pathname.includes(
                          "/case-manager/analytics"
                        ) ? (
                          <FaChartLine />
                        ) : (
                          <FaChartLine />
                        )}
                      </span>
                      Analytics
                    </NavLink>
                  </li>

                  <ShowForType type={userTypes.CASE_MANAGER}>
                    <li className="header-item-menu">
                      <HeaderChatList />
                    </li>
                  </ShowForType>
                  <ShowForType type={userTypes.CASE_MANAGER}>
                    <li className="header-item-menu">
                      <Tooltip title="Survey">
                        <div class="">
                          <FaTasks
                            onClick={() => navigation("/case-manager/survey")}
                            size={20}
                            color="#000"
                            title="Survey"
                          />
                        </div>
                      </Tooltip>
                    </li>
                  </ShowForType>
                </ul>
              </div>
            </div>

            <div className="header-menu-right flex align-items-center">
              <div className="service-menu">
                <ul className="menu-ul-container">
                  <li>
                    <Link to={"/case-manager/profile-detail"}>Account</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="manager-menu show-for-md-device">
            <ul>
              <li className="active">
                <Link
                  to={"/case-manager/patient/list"}
                  style={{
                    color: window.location.pathname.includes(
                      "/case-manager/patient/list"
                    )
                      ? "#1890ff"
                      : "#000000",
                  }}
                >
                  <span>
                    <img src="assets/images/personal_injury.svg" alt="" />
                  </span>
                  Patients
                </Link>
              </li>

              <li className="header-item-menu">
                <RefferalMenu />
              </li>
              <li className="header-item-menu">
                <Link to={"/provider"}>
                  <span>
                    <img src="assets/images/provider_menu.svg" alt="" />
                  </span>
                  Provider
                </Link>
              </li>
              <li className="header-item-menu">
                <Link to={"/case-manager/notification"}>
                  <span>
                    <img src="assets/images/notifications_nav.svg" alt="" />
                  </span>
                  Notification
                </Link>
              </li>
              <li className="header-item-menu">
                <NavLink
                  to={"/case-manager/analytics"}
                  style={{
                    color: window.location.pathname.includes("/analytics")
                      ? "#1890ff"
                      : "#000000",
                  }}
                >
                  <span>
                    {window.location.pathname.includes(
                      "/case-manager/analytics"
                    ) ? (
                      <FaChartLine />
                    ) : (
                      <FaChartLine />
                    )}
                  </span>
                  Analytics
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </header>
    </>
  );
};
export default HeaderComponent;
const { Item } = Menu;

const MenuItem = ({ link, activePath, label }) => {
  const isActive = window.location.pathname.includes(activePath);
  return (
    <Item id="navreffer">
      <NavLink to={link} style={{ color: isActive ? "#1890ff" : "#000000" }}>
        {label}
      </NavLink>
    </Item>
  );
};

const renderSubMenu = () => (
  <Menu>
    <MenuItem
      link="/case-manager/referral/incoming"
      activePath="/incoming"
      label="Incoming"
    />
    <MenuItem
      link="/case-manager/referral/outgoing"
      activePath="/outgoing"
      label="Outgoing"
    />
  </Menu>
);

const renderMenu = () => (
  <Menu style={{ width: "auto", border: "none", padding: 0 }}>
    <MenuItem
      link="/case-manager/bookings"
      activePath="/bookings"
      label="Home Health"
    />
    <Popover
      id="popover"
      content={renderSubMenu}
      placement="rightTop"
      overlayStyle={{ padding: 0 }}
    >
      <Item>
        <span
          style={{
            padding: 0,
            fontSize: 14,
            color: window.location.pathname.includes("/referral")
              ? "#1890ff"
              : "#000000",
          }}
        >
          Provider
          <span style={{ marginLeft: 30 }}>
            <IoIosArrowForward color="black" />
          </span>
        </span>
      </Item>
    </Popover>
  </Menu>
);
function getSpanStyle(...paths) {
  return {
    fontWeight: 500,
    fontSize: 14,
    textTransform: "uppercase",
    display: "flex",
    color: paths.some((path) => window.location.pathname.includes(path))
      ? "#1890ff"
      : "#000000",
  };
}

function getIcon(...paths) {
  if (paths.some((path) => window.location.pathname.includes(path))) {
    return <img src="assets/images/view_list-active.svg" alt="" />;
  } else {
    return <img src="assets/images/view_list.svg" alt="" />;
  }
}
const RefferalMenu = ({ isMobile = false }) => {
  let navStyles = {
    color: window.location.pathname.includes("/case-manager/bookings")
      ? "#1890ff"
      : "#000000",
  };

  if (isMobile) {
    navStyles = {
      color: window.location.pathname.includes("/case-manager/bookings")
        ? "#1890ff"
        : "#000000",
      display: "flex",
      alignItems: "center",
    };
  }

  return (
    // <li className={isMobile ? "active" : ""}>
    //   <NavLink to={"/case-manager/bookings"} style={navStyles}>
    //     <span>
    //       <img src="assets/images/provider_menu.svg" alt="" />
    //     </span>
    //     {isMobile ? (
    //       <span style={{ marginLeft: "12px" }}>Referral</span>
    //     ) : (
    //       "REFERRAL"
    //     )}
    //   </NavLink>
    // </li>

    <li>
      <Popover id="popover" content={renderMenu} placement="bottomLeft" overlayStyle={{ padding: 0 }}>
      <span style={getSpanStyle("/referral", "/bookings")}>
                          {getIcon("/referral", "/bookings")}
                          <div style={{ marginRight: "12px" }}></div>
                          Referral
                        </span>
      </Popover>
    </li>
  );
};
